import firebase from "gatsby-plugin-firebase"
import { useAuth } from "gatsby-theme-firebase"

export const useTaskUpdate = () => {
  const { profile } = useAuth()

  const updateTaskWithId = (id, status) => {
    if (profile) {
      firebase
        .firestore()
        .collection(process.env.GATSBY_FIRESTORE_USERS)
        .doc(profile.email)
        .get()
        .then(function(doc) {
          if (doc.exists) {
            return doc.data().tasks
          }
        })
        .then(function(tasks) {
          tasks.forEach(task => {
            if (task.id == id) {
              task.status = status
            }
          })
          firebase
            .firestore()
            .collection(process.env.GATSBY_FIRESTORE_USERS)
            .doc(profile.email)
            .update({
              tasks: tasks,
            })
        })
    }
  }

  return updateTaskWithId
}

export default useTaskUpdate
