import React, { useReducer, useState, useEffect } from "react"
import TaskCard from "../components/taskCard"
import styled from "styled-components"
import { INLINES } from "@contentful/rich-text-types"
import { navigate } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import BackgroundImage from "gatsby-background-image"
import { useAuth } from "gatsby-theme-firebase"
import ProgressBar from "../components/progressBar"
import Img from "gatsby-image"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import firebase from "gatsby-plugin-firebase"

const website_url = "https://youchangeearth.org"
const options = {
  renderText: text =>
    text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  renderNode: {
    [INLINES.HYPERLINK]: node => {
      return (
        <a
          href={node.data.uri}
          target={`${
            node.data.uri.startsWith(website_url) ? "_self" : "_blank"
          }`}
          rel={`${
            node.data.uri.startsWith(website_url) ? "" : "noopener noreferrer"
          }`}
          style={{ textDecoration: "none", color: "var(--green)" }}
        >
          {node.content[0].value}
        </a>
      )
    },
  },
}

const modalStyles = {
  modal: {
    borderRadius: "0.45em",
    textAlign: "center",
    maxWidth: "500px",
  },
}

const device = {
  mobileS: `(min-width: 320px)`,
  mobileM: `(min-width: 375px)`,
  mobileL: `(min-width: 425px)`,
  tablet: `(min-width: 768px)`,
  laptop: `(min-width: 1024px)`,
  laptopL: `(min-width: 1440px)`,
  desktop: `(min-width: 2000px)`,
}

const CustomDiv = styled.div`
  min-width: 0;
`

const MainContainer = styled(CustomDiv)`
  position: fixed;
  overflow-y: scroll;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 320px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  @media ${device.tablet} {
    background-size: auto;
  }
  @media ${device.desktop} {
    background-size: 3000px auto;
  }
`

const TopContainer = styled(BackgroundImage)`
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  padding: 10em 2em 1em 2em;
  @media ${device.tablet} {
    display: grid;
    grid-template-columns: 1fr 8fr 1fr;
    padding: 13em 3em 1em 3em;
    grid-gap: 1em;
    align-items: center;
  }
  @media ${device.desktop} {
    padding: 30em 15em 2em 15em;
  }
  &:before {
    background-position: center;
  }
`

const MainTitle = styled.h2`
  color: #fff;
  align-self: end;
  font-size: 2rem;
  margin-bottom: 2rem;
  font-weight: 600;
`

const DescriptionContainer = styled(CustomDiv)`
  display: flex;
  background-color: #fff;
  padding: 2em 2em 1em 2em;
  @media ${device.tablet} {
    display: grid;
    grid-template-columns: 1fr 8fr 1fr;
    padding: 3em;
    grid-gap: 1em;
    max-width: 100%;
  }
  @media ${device.desktop} {
    padding-left: 15em;
    padding-right: 15em;
  }
`

const ImageContainer = styled(CustomDiv)`
  display: flex;
  flex-wrap: wrap-reverse;
  padding-bottom: 2em;
  @media ${device.tablet} {
    flex-wrap: no-wrap;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 7%;
    padding-top: 2em;
    align-items: center;
    background-color: #fff;
  }
`

const TaskContainer = styled(CustomDiv)`
  background-color: #eaf2ff;
  display: flex;
  padding: 3em 2em 1em 2em;
  min-width: 0;
  @media (min-width: 875px) {
    display: grid;
    grid-template-columns: 1fr 8fr 1fr;
    grid-gap: 1em;
    padding: 3em;
  }
  @media ${device.desktop} {
    padding-left: 15em;
    padding-right: 15em;
  }
`

const StatsContainer = styled(CustomDiv)`
  background-color: #fff;
  display: flex;
  padding: 3em 2em 3em 2em;
  @media ${device.tablet} {
    display: grid;
    grid-template-columns: 1fr 8fr 1fr;
    grid-gap: 1em;
    padding: 3em;
  }
  @media ${device.desktop} {
    margin: 0 25em 0 25em;
    text-align: center;
  }
`

const SharesContainer = styled(CustomDiv)`
  display: flex;
  padding: 3em 1em 5em 1em;
  background-color: #7298e4;
  text-align: center;
  flex-flow: row wrap;
  @media (min-width: 670px) {
    display: grid;
    grid-template-columns: 1fr 8fr 1fr;
    grid-gap: 1em;
  }
`

const ShareButton = styled.button`
  border-radius: 0.6em;
  border: none;
  background-color: #fff;
  width: 9em;
  height: 3.5em;
  margin-right: 1em;
  margin-bottom: 1em;
  text-align: center;
  cursor: pointer;
  color: black;
`

function reducer(progressState, action) {
  switch (action.type) {
    case "done":
      if (!progressState.doneTasks.includes(action.payload)) {
        return { doneTasks: [...progressState.doneTasks, action.payload] }
      }
      return progressState
    case "no":
      if (!progressState.doneTasks.includes(action.payload)) {
        return { doneTasks: [...progressState.doneTasks, action.payload] }
      }
      return progressState
    default:
      throw new Error()
  }
}

const initialState = { doneTasks: [] }

const SampleProject = props => {
  const post = props.data.contentfulProjectNew
  const { isLoggedIn, profile } = useAuth()
  const [progressState, dispatch] = useReducer(reducer, initialState)
  const [modalIsOpen, setModalIsOpen] = useState(true)

  // Checks if user has this project added to their dashboard
  const [isProjectAdded, setIsProjectAdded] = useState(false)

  // Current projects/tasks
  const [currentProjects, setCurrentProjects] = useState([])
  const [currentTasks, setCurrentTasks] = useState([])

  useEffect(() => {
    if (isLoggedIn && profile) {
      firebase
        .firestore()
        .collection(process.env.GATSBY_FIRESTORE_USERS)
        .doc(profile.email)
        .get()
        .then(doc => {
          if (doc.exists) {
            setCurrentProjects(doc.data().interests)
            setCurrentTasks(doc.data().tasks)
          }
        })
    }
  }, [isLoggedIn, profile])

  useEffect(() => {
    if (isLoggedIn && profile) {
      firebase
        .firestore()
        .collection(process.env.GATSBY_FIRESTORE_USERS)
        .doc(profile.email)
        .get()
        .then(function(doc) {
          if (doc.exists) {
            const interests = doc.data().interests
            interests.forEach(interest => {
              if (post.id == interest.id) {
                setIsProjectAdded(true)
              }
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [isLoggedIn])

  const handleCopy = e => {
    var url = document.createElement("textarea")
    url.innerText = window.location.href
    document.body.appendChild(url)
    url.select()
    document.execCommand("copy")
    url.remove()
  }

  const updateCompletedData = () => {
    const increment = firebase.firestore.FieldValue.increment(1)

    // Increment completed projects count

    firebase
      .firestore()
      .collection("data")
      .doc("completedProjectData")
      .update({ totalNumberCompleted: increment, [post.id]: increment })
      .then(console.log("Completed projects updated"))
      .catch(function(error) {
        console.error("Error adding completed project: ", error)
      })
  }

  const checkCompletion = () => {
    if (isLoggedIn && isProjectAdded) {
      if (post.tasks.length - progressState.doneTasks.length === 1) {
        updateCompletedData()
      }
    }
  }

  const updateStartedData = () => {
    const increment = firebase.firestore.FieldValue.increment(1)

    // Increment started projects count

    firebase
      .firestore()
      .collection("data")
      .doc("startedProjectData")
      .update({ totalNumberStarted: increment, [post.id]: increment })
      .then(console.log("Started projects updated"))
      .catch(function(error) {
        console.error("Error adding started project: ", error)
      })
  }

  const addProject = () => {
    if (isLoggedIn && profile && !isProjectAdded) {
      firebase
        .firestore()
        .collection(process.env.GATSBY_FIRESTORE_USERS)
        .doc(profile.email)
        .update({
          interests: [...currentProjects, post],
          tasks: [...currentTasks, ...post.tasks].flat(),
        })
        .then(() => {
          console.log("Document updated to Firestore with ID: ", profile.email)
        })
        .catch(function(error) {
          console.error("Error adding document: ", error)
        })
      setIsProjectAdded(true)
      updateStartedData()
    }
  }

  return (
    <MainContainer>
      <SEO title={post.name} />
      {isLoggedIn && (
        <div
          style={{
            position: "fixed",
            bottom: "0",
            backgroundColor: "#fff",
            height: "60px",
            zIndex: "2",
            width: "100%",
            paddingLeft: "4em",
            paddingRight: "4em",
            paddingTop: "15px",
          }}
        >
          <ProgressBar
            doneTasks={progressState.doneTasks.length}
            totalTasks={post.tasks.length}
          />
        </div>
      )}
      <button
        onClick={() => navigate(-1)}
        style={{
          backgroundColor: "#05b063",
          color: "white",
          borderRadius: "0.5rem",
          padding: "0.5rem 1rem",
          margin: "2rem",
          position: "absolute",
          zIndex: "999",
        }}
      >
        Back
      </button>
      <TopContainer
        fluid={[
          `linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.6)
    )`,
          post.banner.fluid,
        ]}
        style={{
          backgroundReapeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          objectFit: "cover",
          background: "",
        }}
      >
        <CustomDiv />
        <CustomDiv>
          <MainTitle>{post.name}</MainTitle>
          {/* <CustomDiv
            style={{
              backgroundColor: "#fff",
              borderRadius: "0.6em",
              padding: "0.5em 0 0.5em 0",
              textAlign: "center",
              width: "7em",
              marginBottom: "1em",
              alignIterms: "center",
            }}
          >
            <p
              style={{
                marginBottom: "0",
              }}
            >
              <b className="flex flex-row">
                {" "}
                <span className="mt-1 mx-2">
                  <svg
                    width="15"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.398 7.83a2.744 2.744 0 0 0-.99-.772 3.634 3.634 0 0 0-2.525-4.564A2.895 2.895 0 0 0 9.048.843 2.896 2.896 0 0 0 6.998 0c-.771 0-1.5.3-2.048.842a2.895 2.895 0 0 0-.835 1.653A3.634 3.634 0 0 0 1.59 7.059 2.733 2.733 0 0 0 0 9.536a2.73 2.73 0 0 0 2.726 2.725h8.545a2.73 2.73 0 0 0 2.727-2.726c0-.618-.213-1.224-.6-1.705z"
                      fill="#00CC76"
                    />
                    <path
                      d="M14.002 9.536a2.732 2.732 0 0 0-1.59-2.478 3.634 3.634 0 0 0-2.525-4.564A2.895 2.895 0 0 0 9.052.843 2.896 2.896 0 0 0 7.002 0v12.262h4.273a2.73 2.73 0 0 0 2.727-2.726z"
                      fill="#00AA95"
                    />
                    <path
                      d="M9.223 6.913l-.674-.674L7.477 7.31V4.151h-.954v1.44L5.452 4.519l-.675.674L6.523 6.94v2.09L5.452 7.959l-.675.674 1.746 1.746v5.425h.954V8.659l1.746-1.746z"
                      fill="#C1674C"
                    />
                    <path
                      d="M8.551 6.239L7.48 7.31V4.151h-.477v11.652h.477V8.659l1.746-1.746-.675-.674z"
                      fill="#8C4A50"
                    />
                  </svg>
                </span>
                15 trees
              </b>
            </p>
          </CustomDiv> */}
        </CustomDiv>
        <CustomDiv />
      </TopContainer>
      <DescriptionContainer>
        <CustomDiv />
        <CustomDiv>
          <p>{documentToReactComponents(post.description.json, options)}</p>
          <ImageContainer>
            <CustomDiv
              style={{
                textAlign: "center",
              }}
            >
              <Img
                fluid={post.secondaryImage.fluid}
                alt="Secondary image for project"
                style={{
                  borderRadius: "0.8em",
                  marginBottom: "0",
                }}
              />
            </CustomDiv>
            <CustomDiv>
              <h3 className="text-2xl text-gray-900 font-medium pb-2 mb-2 mt-4">
                Why is this important?
              </h3>
              {post.why && (
                <p>{documentToReactComponents(post.why.json, options)}</p>
              )}
            </CustomDiv>
          </ImageContainer>
        </CustomDiv>
        <CustomDiv />
      </DescriptionContainer>
      <TaskContainer>
        <CustomDiv />
        <CustomDiv>
          <h3 className="text-3xl text-gray-900 font-medium">Key Actions</h3>
          <br />
          {post.tasks &&
            post.tasks.map(task => (
              <TaskCard
                task={task}
                dispatch={dispatch}
                cardType={"task"}
                checkCompletion={checkCompletion}
                isProjectAdded={isProjectAdded}
                addProject={addProject}
              />
            ))}
          {post.toConsider && (
            <div>
              <h3 className="text-3xl text-gray-900 font-medium">
                To Consider
              </h3>
              <br />
              {post.toConsider.map(task => (
                <TaskCard
                  task={task}
                  cardType={"toConsider"}
                  isProjectAdded={isProjectAdded}
                  addProject={addProject}
                />
              ))}
            </div>
          )}
        </CustomDiv>
        <CustomDiv />
      </TaskContainer>
      {post.takeaways && (
        <StatsContainer>
          <CustomDiv />
          <CustomDiv>
            <h3
              className="text-2xl text-gray-900 font-medium pb-2"
              style={{ marginBottom: "1.5rem" }}
            >
              How it adds up
            </h3>
            <CustomDiv>
              {documentToReactComponents(post.takeaways.json, options)}
            </CustomDiv>
          </CustomDiv>
          <CustomDiv />
        </StatsContainer>
      )}
      <SharesContainer>
        <CustomDiv />
        <CustomDiv
          style={{
            color: "#fff",
            textAlign: "center",
          }}
        >
          <h3 className="text-2xl font-medium pb-2 mb-2">Spread the word!</h3>
          <p>Share this project to your friends, family, and followers.</p>
          <br />
          <a href="mailto:team@youchangeearth.org">
            <ShareButton>
              <p
                style={{
                  margin: "0.2em 0 0.2em",
                }}
              >
                <b>Email</b>
              </p>
            </ShareButton>
          </a>
          <a
            href="https://www.instagram.com/youchangeearthorg/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <ShareButton>
              <p
                style={{
                  margin: "0.2em 0 0.2em",
                }}
              >
                <b>Instagram</b>
              </p>
            </ShareButton>
          </a>
          <a
            href="https://twitter.com/youchangeearth"
            rel="noopener noreferrer"
            target="_blank"
          >
            <ShareButton>
              <p
                style={{
                  margin: "0.2em 0 0.2em ",
                }}
              >
                <b>Twitter</b>
              </p>
            </ShareButton>
          </a>
          <ShareButton onClick={handleCopy}>
            <p
              style={{
                margin: "0.2em 0 0.2em",
              }}
            >
              <b>Copy Link</b>
            </p>
          </ShareButton>
        </CustomDiv>
        <CustomDiv />
        <div />
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSd9urapBTL-r_0QtHCxXXTuqYF7hYf4tn4qUDYtZq5LArg2PA/viewform?usp=sf_link"
          rel="noopener noreferrer"
          target="_blank"
          style={{
            textDecoration: "none",
            color: "var(--green)",
            fontWeight: 700,
          }}
        >
          <p style={{ color: "white" }}>
            We're always looking to improve our list of climate guides! If you
            have any suggestions for new guides or ways we can improve our
            current guides, please fill out the form here.
          </p>
        </a>
      </SharesContainer>
    </MainContainer>
  )
}

export const query = graphql`
  query specificNewProject($slug: String) {
    contentfulProjectNew(slug: { eq: $slug }) {
      id
      slug
      name
      description {
        json
      }
      takeaways {
        json
      }
      tasks {
        name
        id
        description {
          json
        }
        toDo {
          json
        }
      }
      why {
        json
      }
      toConsider {
        id
        name
        toDo {
          json
        }
      }
      banner {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      icon {
        file {
          url
        }
      }
      secondaryImage {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`

export default SampleProject
